import styled from "@emotion/styled";

interface BoxProps {
  title?: string;
  children: any;
  chart?: boolean;
}

const BoxStyled = styled.div<BoxProps>`
  background-color: #fff;
  width: 100%;
  border-radius: 12px;
  min-width: 400px;
  max-width: 900px;
  padding: ${(props) => {
    return props.chart ? "24px 0px;" : "36px;";
  }}
  margin: 0 auto;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Title = styled.h1`
  font-size: 40px;
  text-align: center;
  padding-bottom: 32px;
`;

const Box = ({
  title,
  children,
  chart = false,
}: {
  title?: string;
  children?: unknown;
  chart?: boolean;
}) => {
  return (
    <BoxStyled chart={chart}>
      {title && <Title>{title}</Title>}
      {children}
    </BoxStyled>
  );
};

export { Box };
