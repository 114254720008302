import { flatten, isArray } from "lodash";
import { TitleH2, TitleH4, TitleH6 } from "../titles/titlles";
import { GenericForm } from "../form/GenericForm";
import { CompoundInput, SimpleInput, Select } from "../inputs/inputs";
import { ANDORRA, SPAIN, UK } from "../../constants";
import { EventsFormSection } from "./EventsFormSection";

export const getInitialValues = () => {
  const simulationDurationinYears = 50;

  const initialValues = {
    general: {
      annualGrossIncome: 60000,
      // Año a partir del cual empezamos a tener en cuenta los datos del IPC para calcular la media
      ipcStartYear: 2000,
      // Precio medio al que estoy cambiando la libra (mas bien es superior, pero es un calculo conservador)
      exchangeRatePoundEur: 1.1,
      pensionContributionPerc: 0.06,
      indexaFee: 0.004,
      inversisBankFee: 0.00109,
      portfolioFundsFee: 0.0008,
      taxOptimizationPerc: 0.02,
      residentCountry: SPAIN,
      durationInYears: simulationDurationinYears,
      ordinaryAnnualExpenses: 20000,
      inflationPerYearPerc: 0.03,
    },
    initialAssets: {
      initialSavings: 30000,
      initialInvestment: 26000,
      initialUKPension: 0,
      initialSpanishPension: 0,
    },
    events: {
      annualInvestmentContribution: [
        {
          startDate: 1,
          endDate: 15,
          params: {
            weeklyInvestmentContribution: 250,
          },
        },
      ],
      earlyRetirement: {
        startDate: 15,
        endDate: simulationDurationinYears,
        params: {},
      },
      disinvestment: {
        startDate: 21,
        endDate: simulationDurationinYears,
        params: {
          weeklyDisinvestment: 295,
        },
      },
      payRise: [
        {
          startDate: 1,
          endDate: 10,
          params: {
            payRisePerc: 0.04,
          },
        },
      ],
    },
    scenarios: {
      indexa: {
        annualIndexaProfitRate: 0.032,
      },
      worst: {
        annualIndexaProfitRate: 0.05,
      },
      bad: {
        annualIndexaProfitRate: 0.06,
      },
      realistic: {
        annualIndexaProfitRate: 0.07,
      },
      good: {
        annualIndexaProfitRate: 0.095,
      },
    },
    checkedEvents: [],
  };

  const lastSubmittedFormValuesFromLocalStorage =
    localStorage.getItem("valuesFromForm");

  return lastSubmittedFormValuesFromLocalStorage !== null
    ? JSON.parse(lastSubmittedFormValuesFromLocalStorage)
    : initialValues;
};

export const getSelectedEvents = (values: any) => {
  const filteredSelectedEvents = Object.fromEntries(
    Object.entries(values.events).filter(([eventName, eventValue]) => {
      return values.checkedEvents.includes(eventName);
    })
  );

  return filteredSelectedEvents;
};

const SimulationForm = ({ onSubmit }: { onSubmit: (values: any) => any }) => {
  const handleOnSubmit = (values: any) => {
    function filteredGeneralValues() {
      return Object.fromEntries(
        Object.entries(values.general).filter(
          ([key]) =>
            key !== "pensionContributionPerc" && key !== "exchangeRatePoundEur"
        )
      );
    }

    const selectedEvents = getSelectedEvents(values);

    const valuesToBeSent = {
      ...values,
      // Transformamos el valor para que sea un array de objetos, asi podemos pasar varios segmentos
      general:
        values.general.residentCountry === "Spain"
          ? filteredGeneralValues()
          : values.general,
      events: Object.entries(selectedEvents).reduce(
        (acc, [key, value]: any) => {
          const transformValueIntoArray = isArray(value) ? value : [value];

          const sections = transformValueIntoArray.map(
            ({ startDate, endDate, params }: any) => ({
              startDate,
              duration: endDate - startDate,
              params,
            })
          );

          // De momento hay que hacer esto porque en algunos eventos mandamos un
          // array (porque hay varios segments), pero en otros enviamos solo un segment como objeto
          return { ...acc, [key]: flatten([sections]) };
        },
        selectedEvents
      ),
    };

    localStorage.setItem("valuesFromForm", JSON.stringify(values));

    return onSubmit(valuesToBeSent);
  };

  return (
    <div className="form">
      <TitleH2>Simulación</TitleH2>
      <GenericForm
        initialValues={getInitialValues()}
        onSubmit={handleOnSubmit}
        render={({ values, setFieldValue, FlexFormSection }: any) => {
          return (
            <>
              <TitleH4>General</TitleH4>
              <FlexFormSection>
                <CompoundInput
                  id="annualGrossIncome"
                  inputName="general.annualGrossIncome"
                  label="Salario"
                  icon="€"
                  placeholder="30000"
                />
                <SimpleInput
                  id="ipcStartYear"
                  inputName="general.ipcStartYear"
                  label="Año comienzo ipc"
                  placeholder="2002"
                />
                <CompoundInput
                  id="indexaFee"
                  inputName="general.indexaFee"
                  label="Comision Indexa"
                  placeholder="0,0044"
                  icon="%"
                />
                <CompoundInput
                  id="inversisBankFee"
                  inputName="general.inversisBankFee"
                  label="Comisión Inversis Bank (indexa)"
                  placeholder="0,0014"
                  icon="%"
                />
                <CompoundInput
                  id="portfolioFundsFee"
                  inputName="general.portfolioFundsFee"
                  label="Comision Portfolio (indexa)"
                  placeholder="0,0009"
                  icon="%"
                />
                <CompoundInput
                  id="taxOptimizationPerc"
                  inputName="general.taxOptimizationPerc"
                  label="Optimización de impuestos en la retirada"
                  placeholder="0,03"
                  icon="%"
                />
                <SimpleInput
                  id="durationInYears"
                  inputName="general.durationInYears"
                  label="Duración de la simulación (años)"
                  placeholder="50"
                />
                <Select
                  id="residentCountry"
                  label="Pais de residencia fiscal"
                  options={[SPAIN, UK, ANDORRA]}
                  setFieldValue={setFieldValue}
                />
                {values.general.residentCountry === UK && (
                  <>
                    <SimpleInput
                      id="exchangeRatePoundEur"
                      inputName="general.exchangeRatePoundEur"
                      label="Precio medio del cambio de libra"
                      placeholder="1,12"
                    />
                    <CompoundInput
                      id="pensionContributionPerc"
                      inputName="general.pensionContributionPerc"
                      label="Contribución a la pensión"
                      placeholder="0,07"
                      icon="%"
                    />
                  </>
                )}
                <CompoundInput
                  id="ordinaryAnnualExpenses"
                  inputName="general.ordinaryAnnualExpenses"
                  label="Gastos anuales"
                  placeholder="15000"
                  icon="€"
                />
                <CompoundInput
                  id="inflationPerYearPerc"
                  inputName="general.inflationPerYearPerc"
                  label="Inflacion anual estimada"
                  placeholder="0,04"
                  icon="%"
                />
              </FlexFormSection>
              <TitleH4>Activos iniciales</TitleH4>
              <FlexFormSection>
                <CompoundInput
                  id="initialInvestment"
                  inputName="initialAssets.initialInvestment"
                  label="Inversion inicial"
                  icon="€"
                  placeholder="20000"
                />
                <CompoundInput
                  id="initialSavings"
                  inputName="initialAssets.initialSavings"
                  label="Ahorros iniciales"
                  icon="€"
                  placeholder="15000"
                />
                <CompoundInput
                  id="initialUKPension"
                  inputName="initialAssets.initialUKPension"
                  label="Pension de UK"
                  icon="£"
                  placeholder="1500"
                />
                <CompoundInput
                  id="initialSpanishPension"
                  inputName="initialAssets.initialSpanishPension"
                  label="Pension de España"
                  icon="€"
                  placeholder="1000"
                />
              </FlexFormSection>
              <EventsFormSection
                values={values}
                setFieldValue={setFieldValue}
              />
              <TitleH4>Escenarios</TitleH4>
              <FlexFormSection>
                <div>
                  <TitleH6>Indexa</TitleH6>
                  <CompoundInput
                    id="indexaScenario"
                    inputName="scenarios.indexa.annualIndexaProfitRate"
                    label="Rentabilidad anual"
                    placeholder="0,031"
                    icon="%"
                  />
                </div>
                <div>
                  <TitleH6>Worst</TitleH6>
                  <CompoundInput
                    id="worstScenario"
                    inputName="scenarios.worst.annualIndexaProfitRate"
                    label="Rentabilidad anual"
                    placeholder="0,051"
                    icon="%"
                  />
                </div>
                <div>
                  <TitleH6>Bad</TitleH6>
                  <CompoundInput
                    id="badScenario"
                    inputName="scenarios.bad.annualIndexaProfitRate"
                    label="Rentabilidad anual"
                    placeholder="0,061"
                    icon="%"
                  />
                </div>
                <div>
                  <TitleH6>Realistic</TitleH6>
                  <CompoundInput
                    id="realisticScenario"
                    inputName="scenarios.realistic.annualIndexaProfitRate"
                    label="Rentabilidad anual"
                    placeholder="0,071"
                    icon="%"
                  />
                </div>
                <div>
                  <TitleH6>Good</TitleH6>
                  <CompoundInput
                    id="goodScenario"
                    inputName="scenarios.good.annualIndexaProfitRate"
                    label="Rentabilidad anual"
                    placeholder="0,096"
                    icon="%"
                  />
                </div>
              </FlexFormSection>
            </>
          );
        }}
      />
    </div>
  );
};

export { SimulationForm };
