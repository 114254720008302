import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import styled from "@emotion/styled";

const LogoutButton = () => {
  const { logout } = useAuth0();

  const Logout = styled.button`
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  `;

  const LogoutIcon = styled.img`
    height: 18px;
    color: white;
  `;

  return (
    <Logout onClick={() => logout({ returnTo: window.location.origin })}>
      <LogoutIcon src="/logout.png" alt="logout icon" />
    </Logout>
  );
};

export default LogoutButton;
