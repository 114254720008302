/** @jsxImportSource @emotion/react */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { jsx, css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useState } from "react";
import { TitleH2, TitleH3 } from "../titles/titlles";
import { Box } from "../container/Box";
import { GenericForm } from "../form/GenericForm";
import { CompoundInput } from "../inputs/inputs";
import { Result } from "../display/Result";

const LOCALE = "es-ES";
const round = (number: number) => Math.round(number * 100) / 100;
const formatNumber = (number: number) => round(number).toLocaleString(LOCALE);

interface Data {
  gross: {
    perYear: number;
    perWorkedDay: number;
    perWorkedHour: number;
  };
  minPersFee: number;
  generalExpenses: number;
  taxes: {
    irpfPerc: number;
    ssFeePerc: number;
    irpf: number;
    ssFee: number;
    totalTaxes: number;
    totalTaxesPerc: number;
  };
  net: {
    perYear: number;
    perMonth: number;
    perDay: number;
    perWorkedDay: number;
    perWorkedHour: number;
  };
}

const Article = styled.article`
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const Th = styled.th`
  text-align: left;
  font-weight: bold;
  padding: 8px 16px;
`;

const Td = styled.td`
  padding: 8px 16px;
`;

const IrpfResults = ({ data }: { data: Data }) => {
  return (
    <section>
      <TitleH3>Resultados</TitleH3>
      <Article>
        <Result
          label="Salario bruto"
          value={formatNumber(data.gross.perYear)}
        />
      </Article>
      <Article>
        <Result
          label="Cuota minima personal"
          value={formatNumber(data.minPersFee)}
        />
        <Result
          label="Gastos generales"
          value={formatNumber(data.generalExpenses)}
        />
      </Article>
      <Article>
        <Result
          label="Salario neto anual"
          value={formatNumber(data.net.perYear)}
        />
        <Result
          label="Salario neto mensual"
          value={formatNumber(data.net.perMonth)}
        />
        <Result
          label="Salario neto diario"
          value={formatNumber(data.net.perDay)}
        />
        <Result
          dataTestid="netSalaryPerWorkedDay"
          label="Salario neto por dia trabajado"
          value={formatNumber(data.net.perWorkedDay)}
        />
      </Article>
      {/* <div>
  Monthly Savings after 2.3k/month of expenses :
  {data.netSalary / 12 - 2300}
</div>
<div>
  Anual Savings after 2.3k/month of expenses :
  {data.netSalary - 12 * 2300}
</div> */}
    </section>
  );
};

const Irpf = () => {
  // Cambiar todos estos datos para que se recojan por input, en lugar de estar aqui hardcodeados
  // const bonus = 7000
  // const foodCashPerWorkedDay = 9
  const log = true;
  const holidays = 25;
  const bankHolidays = 12;
  const hoursPerDay = 7.5;
  const daysPerWeek = 5;

  const [data, setData] = useState<Data>();

  const initialValues = { salary: 70000, monthlyExpenses: 1750 };

  //   SyntheticEvent
  const handleFormSubmit = ({ salary }: { salary: number }) => {
    const fetchIrpfInfo = async (salary: number) => {
      if (!process.env.REACT_APP_API_URL) {
        throw new Error("REACT_APP_API_URL must be present in the .env file");
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/getIrpfInfo`,
        {
          method: "POST",
          body: JSON.stringify({ salary }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      setData(data.irpfInfo);
    };

    if (salary) {
      fetchIrpfInfo(Number(salary));
    }
  };

  return (
    <>
      <Box>
        <TitleH2>Calculo del Irpf</TitleH2>
        <GenericForm
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          render={({ FormSection }: any) => (
            <FormSection>
              <CompoundInput
                inputName="salary"
                label="Salario"
                icon="€"
                placeholder="60000"
              />
              <CompoundInput
                inputName="monthlyExpenses"
                label="Gastos mensuales"
                icon="€"
                placeholder="1500"
              />
            </FormSection>
          )}
        />
        {data && <IrpfResults data={data} />}
      </Box>
      {data && (
        <Box>
          <table>
            <thead>
              <tr>
                <Th scope="col">Impuestos</Th>
                <Th scope="col">Porcentaje</Th>
                <Th scope="col">Total</Th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <Th scope="row">Irpf</Th>
                <Td>{formatNumber(data.taxes.irpfPerc)}</Td>
                <Td>{formatNumber(data.taxes.irpf)}</Td>
              </tr>
              <tr>
                <Th scope="row">Seguridad Social</Th>
                <Td>{formatNumber(data.taxes.ssFeePerc)}</Td>
                <Td>{formatNumber(data.taxes.ssFee)}</Td>
              </tr>

              <tr>
                <Th scope="row">Total</Th>
                <Td>{formatNumber(data.taxes.totalTaxesPerc)}</Td>
                <Td>{formatNumber(data.taxes.totalTaxes)}</Td>
              </tr>
            </tbody>
          </table>
        </Box>
      )}
    </>
  );
};

export { Irpf };
