import { useState } from "react";
import { TitleH2, TitleH3 } from "../titles/titlles";
import { Box } from "../container/Box";
import { GenericForm } from "../form/GenericForm";
import { CompoundInput, SimpleInput } from "../inputs/inputs";
import { Result } from "../display/Result";

const EquivalentMoney = () => {
  const initialValues = {
    formData: {
      amount: 1000,
      year: 3,
      inflationPerYearPerc: 0.03,
    },
  };

  const getFutureEquivalentMoney = ({
    amount,
    year,
    inflationPerYearPerc,
  }: {
    amount: number;
    year: number;
    inflationPerYearPerc: number;
  }) => {
    return amount * Math.pow(1 + inflationPerYearPerc, year);
  };

  const getPresentEquivalentMoney = ({
    amount,
    year,
    inflationPerYearPerc,
  }: {
    amount: number;
    year: number;
    inflationPerYearPerc: number;
  }) => {
    return amount / Math.pow(1 + inflationPerYearPerc, year);
  };

  const getInitialValues = () => {
    const lastSubmittedFormValuesFromLocalStorage =
      localStorage.getItem("equivalentMoney");

    return lastSubmittedFormValuesFromLocalStorage !== null
      ? JSON.parse(lastSubmittedFormValuesFromLocalStorage)
      : initialValues;
  };

  const [present, setPresent] = useState(getInitialValues().present || 0);
  const [future, setFuture] = useState(getInitialValues().future || 0);

  const onSubmit = (values: {
    amount: number;
    inflationPerYearPerc: number;
    year: number;
  }) => {
    const futureValue = Number(getFutureEquivalentMoney(values).toFixed(2));
    const presentValue = Number(getPresentEquivalentMoney(values).toFixed(2));

    setFuture(futureValue);
    setPresent(presentValue);

    localStorage.setItem(
      "equivalentMoney",
      JSON.stringify({
        formData: values,
        future: futureValue,
        present: presentValue,
      })
    );
  };

  return (
    <Box>
      <TitleH2>Dinero equivalente</TitleH2>
      <GenericForm
        initialValues={getInitialValues().formData}
        onSubmit={onSubmit}
        render={({ FormSection }: any) => {
          return (
            <FormSection>
              <CompoundInput
                inputName="amount"
                label="Cantidad"
                icon="€"
                placeholder="1500"
              />
              <SimpleInput inputName="year" label="Años" placeholder="5" />
              <CompoundInput
                inputName="inflationPerYearPerc"
                label="Inflacion"
                placeholder="0,035"
                icon="%"
              />
            </FormSection>
          );
        }}
      />

      {future !== 0 && present !== 0 && (
        <section>
          <TitleH3>Resultados</TitleH3>
          <Result
            label="Futuro: hace a la referencia de la cantidad de dinero que podrá alcanzar una inversión en una fecha futura al ganar intereses a una tasa compuesta"
            value={future}
          />
          <Result
            label="Presente: cuánto vale en este momento una determinada cantidad de dinero que recibirás en el futuro"
            value={present}
          />
        </section>
      )}
    </Box>
  );
};

export { EquivalentMoney };
