import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const LineChartCustom = ({
  dataScenario,
  scenario,
  linesToShow,
}: {
  dataScenario: any;
  scenario: string;
  linesToShow: any;
}) => {
  const data = Object.values(dataScenario);

  data.map((item: any) => (item["name"] = item.year));

  const formatNumbers = (value: any) => {
    // TODO: aL intentar cambiar el tipo de TypeScript me da este mensaje de error: "Type 'string' is not assignable to type 'number'"
    let result = value;
    if (value >= 1000000) {
      const division = value / 1000000;

      result = `${division}M €`;
    }
    if (value > 1000 && value < 1000000) {
      const division = value / 1000;

      result = `${division}k €`;
    }

    return `${result}`;
  };

  return (
    <div data-testid={scenario}>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis tickFormatter={(value: any) => formatNumbers(value)} />
          <Tooltip
            labelFormatter={(label) => `Año ${label}`}
            formatter={(value: any, name: any, props: any) => {
              let formattedValue =
                value > 1000 ? Math.trunc(value / 1000) : value;

              const result = new Intl.NumberFormat("de-DE", {
                maximumFractionDigits: 0,
              }).format(formattedValue);

              const resultWithCurrency =
                value > 1000 ? `${result}k €` : `${result} €`;

              return props.dataKey ===
                "investmentData.accumulatedPotentialBenefits"
                ? `${resultWithCurrency} (${props.payload.investmentData.accumulatedPotentialBenefitsPerc.toFixed(
                    2
                  )}%)`
                : resultWithCurrency;
            }}
          />
          <Legend />
          {linesToShow.map(({ name, dataKey, stroke }: any) => (
            <Line
              key={name}
              name={name}
              dataKey={dataKey}
              stroke={stroke}
              type="monotone"
              strokeWidth={3}
              dot={false}
              activeDot={{ r: 8 }}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export { LineChartCustom };
