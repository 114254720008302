import styled from "@emotion/styled";

const ResultItem = styled.div`
  display: flex;
  padding-top: 12px;
  border-radius: 0.25rem;
`;

const resulItemCommon = `
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  display: block;
`;

const ResultItemLabel = styled.span`
  ${resulItemCommon}
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
  min-width: 240px;
`;

const ResultItemNumbers = styled.span`
  ${resulItemCommon}
  flex: 1;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  display: flex;
  align-items: center;
  min-width: 88px;
`;

export const Result = ({
  dataTestid,
  label,
  value,
}: {
  dataTestid?: string;
  label: string;
  value: any;
}) => {
  return (
    <ResultItem data-testid={dataTestid}>
      <ResultItemLabel>{label}</ResultItemLabel>
      <ResultItemNumbers>{value}</ResultItemNumbers>
    </ResultItem>
  );
};
