import { Formik, Form } from "formik";
import styled from "@emotion/styled";

const Button = styled.button`
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  border: none;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  width: 100%;
  margin-top: 24px;
  cursor: pointer;
`;

const FormSection = styled.section`
  > div {
    padding-top: 12px;
  }
`;

const FlexFormSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 2%;

  > div {
    width: 49%;
    padding-top: 12px;
  }

  @media (max-width: 1024px) {
    flex-direction: column;

    > div {
      width: 100%;
    }
  }
`;

const GenericForm = ({
  initialValues,
  onSubmit,
  buttonText = "Calcular",
  render,
  withoutButton,
}: {
  initialValues: any;
  onSubmit: any;
  buttonText?: string;
  render: any;
  withoutButton?: boolean;
}) => {
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, setFieldValue }) => {
        return (
          <>
            <Form>
              {render({
                values: values,
                setFieldValue: setFieldValue,
                FormSection: FormSection,
                FlexFormSection: FlexFormSection,
              })}
              {!withoutButton && (
                <Button className="general" type="submit">
                  {buttonText}
                </Button>
              )}
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export { GenericForm };
