import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import styled from "@emotion/styled";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  const Login = styled.button`
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  `;

  const LoginIcon = styled.img`
    height: 22px;
    color: white;
  `;

  return (
    <Login onClick={() => loginWithRedirect()}>
      <LoginIcon src="/login.png" alt="login icon" />
    </Login>
  );
};

export default LoginButton;
