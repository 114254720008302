import { prop } from "lodash/fp";
import { FieldArray, FieldArrayRenderProps } from "formik";
import { ReactElement } from "react";
import { TitleH4 } from "../titles/titlles";
import { CompoundInput, Checkbox, Range } from "../inputs/inputs";
import styled from "@emotion/styled";

const EventBoxStyled = styled.div`
  border: 1px solid grey;
  padding: 12px 16px 12px 24px;
  border-radius: 8px;
  margin-top: 24px;
  margin-bottom: 16px;
  position: relative;
  display: flex;
  justify-content: space-between;

  > div {
    width: 46%;
  }
`;

const EventBox = ({
  children,
  removeButton,
  ...rest
}: {
  children: ReactElement;
  eventName: string;
  event: any;
  setFieldValue: any;
  simulationDuration: number;
  removeButton?: () => void;
}) => {
  return (
    <EventBoxStyled>
      <Range {...rest} />
      {children}
      {removeButton && (
        <RemoveButton type="button" onClick={removeButton}>
          X
        </RemoveButton>
      )}
    </EventBoxStyled>
  );
};

const RemoveButton = styled.button`
  background-color: #343a40;
  border-radius: 50%;
  color: white;
  width: 20px;
  height: 20px;
  border: none;
  position: absolute;
  right: -9px;
  top: -9px;
`;

const AddSection = styled.button`
  color: #fff;
  background-color: #343a40;
  border: none;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 16px;
`;

function handleOnchange(event: any, setFieldValue: any, checkedEvents: any) {
  const { value } = event.target;

  if (checkedEvents.includes(value)) {
    const filteredCheckbox = checkedEvents.filter((event: any) => {
      return event !== value;
    });

    setFieldValue("checkedEvents", filteredCheckbox);
  } else {
    setFieldValue("checkedEvents", [...checkedEvents, value]);
  }
}

const EventBoxShellStyled = styled.div`
  position: relative;
  padding: 16px;
  margin: 20px 0;
  box-shadow: 0px 0px 15px 0 rgb(0 0 0 / 20%), 6px 0 0 #fff;
  border-radius: 4px;
`;

const EventBoxShell = ({
  checkedEvents,
  eventName,
  eventDescription,
  children,
  setFieldValue,
  addSection,
}: {
  checkedEvents: any;
  eventName: string;
  eventDescription: string;
  children: ReactElement;
  setFieldValue: any;
  addSection: () => void;
}) => {
  return (
    <EventBoxShellStyled data-testid={eventName}>
      <Checkbox
        name="checkedEvents"
        value={eventName}
        label={eventDescription}
        onChange={(event: any) =>
          handleOnchange(event, setFieldValue, checkedEvents)
        }
        checked={checkedEvents.includes(eventName)}
        withAddSectionButton={true}
      />
      {checkedEvents.includes(eventName) && (
        <>
          <AddSection type="button" onClick={addSection}>
            Añadir sección
          </AddSection>
          {children}
        </>
      )}
    </EventBoxShellStyled>
  );
};

export const EventsFormSection = ({ values, setFieldValue }: any) => {
  return (
    <>
      <TitleH4>Eventos</TitleH4>
      <FieldArray
        name="events.annualInvestmentContribution"
        render={({ remove, push, name }: FieldArrayRenderProps) => {
          return (
            <EventBoxShell
              checkedEvents={values.checkedEvents}
              eventName="annualInvestmentContribution"
              eventDescription="Dinero que se aporta anualmente a inversion en indexa"
              setFieldValue={setFieldValue}
              addSection={() =>
                push({
                  startDate: 25,
                  endDate: 45,
                  params: {
                    weeklyInvestmentContribution: 50,
                  },
                })
              }
            >
              <>
                {prop(name, values).map((event: any, index: any) => {
                  return (
                    <EventBox
                      eventName={`annualInvestmentContribution.${index}`}
                      event={event}
                      setFieldValue={setFieldValue}
                      simulationDuration={values.general.durationInYears}
                      data-testid={`eventBox${index}`}
                      key={index}
                      removeButton={
                        prop(name, values).length > 1
                          ? () => remove(index)
                          : undefined
                      }
                    >
                      <>
                        <CompoundInput
                          id={`annualInvestmentContributionWeeklyInvestmentContribution${index}`}
                          inputName={`events.annualInvestmentContribution.${index}.params.weeklyInvestmentContribution`}
                          label="Contribucion semanal"
                          icon="€"
                          placeholder="200"
                        />
                      </>
                    </EventBox>
                  );
                })}
              </>
            </EventBoxShell>
          );
        }}
      />
      <div>
        <Checkbox
          name="checkedEvents"
          value="earlyRetirement"
          label="Retiro anticipado (no se cobra ninguna pension porque no se
tiene la edad necesaria)"
          onChange={(event: any) =>
            handleOnchange(event, setFieldValue, values.checkedEvents)
          }
          checked={values.checkedEvents.includes("earlyRetirement")}
        />
        {values.checkedEvents.includes("earlyRetirement") && (
          <EventBox
            eventName="earlyRetirement"
            event={values.events.earlyRetirement}
            setFieldValue={setFieldValue}
            simulationDuration={values.general.durationInYears}
          >
            <></>
          </EventBox>
        )}
      </div>
      <div>
        <Checkbox
          name="checkedEvents"
          value="disinvestment"
          label="Desinvertir, retirar mensualmente parte del dinero invertido"
          onChange={(event: any) =>
            handleOnchange(event, setFieldValue, values.checkedEvents)
          }
          checked={values.checkedEvents.includes("disinvestment")}
        />
        {values.checkedEvents.includes("disinvestment") && (
          <EventBox
            eventName="disinvestment"
            event={values.events.disinvestment}
            setFieldValue={setFieldValue}
            simulationDuration={values.general.durationInYears}
          >
            <CompoundInput
              id="weeklyDisinvestment"
              inputName="events.disinvestment.params.weeklyDisinvestment"
              label="Retirada semanal"
              placeholder="50"
              icon="€"
            />
          </EventBox>
        )}
      </div>
      <FieldArray
        name="events.payRise"
        render={({ remove, push, name }: FieldArrayRenderProps) => {
          return (
            <EventBoxShell
              checkedEvents={values.checkedEvents}
              eventName="payRise"
              eventDescription="Aumento anual de sueldo bruto"
              setFieldValue={setFieldValue}
              addSection={() =>
                push({
                  startDate: 25,
                  endDate: 45,
                  params: {
                    payRisePerc: 0.03,
                  },
                })
              }
            >
              <>
                {prop(name, values).map((event: any, index: any) => {
                  return (
                    <EventBox
                      eventName={`payRise.${index}`}
                      event={event}
                      setFieldValue={setFieldValue}
                      simulationDuration={values.general.durationInYears}
                      data-testid={`eventBox${index}`}
                      key={index}
                      removeButton={
                        prop(name, values).length > 1
                          ? () => remove(index)
                          : undefined
                      }
                    >
                      <CompoundInput
                        id={`payRisePerc${index}`}
                        inputName={`events.payRise.${index}.params.payRisePerc`}
                        label="Porcentaje de subida"
                        placeholder="0,02"
                        icon="%"
                      />
                    </EventBox>
                  );
                })}
              </>
            </EventBoxShell>
          );
        }}
      />
    </>
  );
};
