import { useState } from "react";
import { TitleH2, TitleH3 } from "../titles/titlles";
import { Box } from "../container/Box";
import { GenericForm } from "../form/GenericForm";
import { SlideCheckBox, SimpleInput, CompoundInput } from "../inputs/inputs";
import { Result } from "../display/Result";
import "./styles.css";

export const getMonthlyPayment = ({
  loan,
  interest,
  period,
  periodType,
}: {
  loan: number;
  interest: number;
  period: number;
  periodType: boolean;
}) => {
  // formula a=C0*i/1-(1+i)-n
  // a: cuota periodica constante
  // C0: capital prestado
  // i: tipo de interes anual prestado
  // n: numero de periodos

  const months = periodType ? period * 12 : period;

  const monthlyInterest = interest / 100 / 12;
  let monthlyPayment;

  monthlyPayment =
    (loan * monthlyInterest) / (1 - (1 + monthlyInterest) ** -months);

  return Number(monthlyPayment.toFixed(2));
};

export const getTableData = (formData: any) => {
  const { loan, interest, period, periodType } = formData;

  let data: any = {
    0: {
      month: 0,
      mensualidad: undefined,
      interest: undefined,
      cuotaAmortizacion: undefined,
      capital: loan,
      capitalAmortizado: 0,
    },
  };

  let totalInterest = 0;
  let totalCuotaAmortizacion = 0;
  const months = periodType ? period * 12 : period;
  const percentageInterest = interest / 100;

  for (let month = 1; month <= months; month++) {
    const cuotaAmortizacion =
      getMonthlyPayment(formData) -
      (data[month - 1].capital * percentageInterest) / 12;

    data[month] = {
      month: month,
      mensualidad: getMonthlyPayment(formData),
      interest: data[month - 1].capital * (percentageInterest / 12),
      cuotaAmortizacion,
      capital: data[month - 1].capital - cuotaAmortizacion,
      capitalAmortizado: data[month - 1].capitalAmortizado + cuotaAmortizacion,
    };

    totalInterest =
      totalInterest + (data[month - 1].capital * percentageInterest) / 12;
    totalCuotaAmortizacion = totalCuotaAmortizacion + cuotaAmortizacion;
  }

  return { data, totalInterest, totalCuotaAmortizacion };
};

const Mortgage = () => {
  const initialValues = {
    loan: 270000,
    interest: 3.15,
    period: 30,
    periodType: true,
  };

  const [formData, setFormData] = useState(initialValues);
  const [results, setResults] = useState(false);

  const round = (number = 0) => Number(number.toFixed(2));

  interface MortgageRow {
    month: number;
    mensualidad: number;
    interest: number;
    cuotaAmortizacion: number;
    capital: number;
    capitalAmortizado: number;
  }

  const getMortgageRow = ({
    month,
    mensualidad,
    interest,
    cuotaAmortizacion,
    capital,
    capitalAmortizado,
  }: MortgageRow) => {
    return (
      <tr key={month}>
        <td>{month}</td>
        <td>{round(mensualidad)}</td>
        <td>{round(interest)}</td>
        <td>{round(cuotaAmortizacion)}</td>
        <td>{round(capital)}</td>
        <td>{round(capitalAmortizado)}</td>
      </tr>
    );
  };

  return (
    <>
      <Box>
        <TitleH2>Calculadora de prestamo</TitleH2>
        <GenericForm
          initialValues={initialValues}
          onSubmit={(values: any) => {
            setFormData(values);
            setResults(true);
          }}
          render={({ values, FormSection }: any) => {
            return (
              <FormSection>
                <CompoundInput
                  inputName="loan"
                  label="Prestamo hipotecario"
                  icon="€"
                  placeholder="250000"
                />
                <CompoundInput
                  inputName="interest"
                  label="Interes"
                  icon="%"
                  placeholder="3.15"
                />
                <SimpleInput
                  inputName="period"
                  label={
                    <SlideCheckBox
                      values={values}
                      inputName="periodType"
                      optionTrue="Años"
                      optionFalse="Meses"
                    />
                  }
                  placeholder="25"
                />
              </FormSection>
            );
          }}
        />
        {results && (
          <section>
            <TitleH3>Resultados</TitleH3>
            <Result
              dataTestid="monthlyPayment"
              label="Pago mensual"
              value={getMonthlyPayment(formData)}
            />
            <Result
              dataTestid="totalInterest"
              label="Interes Total"
              value={round(getTableData(formData).totalInterest)}
            />
            <Result
              dataTestid="totalPayment"
              label="Pago Total"
              value={round(
                getTableData(formData).totalInterest +
                  getTableData(formData).totalCuotaAmortizacion
              )}
            />
          </section>
        )}
      </Box>
      {results && (
        <Box>
          <table>
            <thead>
              <tr>
                <th>Mes</th>
                <th>Mensualidad</th>
                <th>Interes</th>
                <th>Cuota de amortizacion</th>
                <th>Capital por amortizar</th>
                <th>Capital amortizado</th>
              </tr>
            </thead>
            <tbody>
              {(
                Object.values(getTableData(formData).data) as MortgageRow[]
              ).map(getMortgageRow)}
            </tbody>
            <tfoot>
              <tr>
                <th>Total</th>
                <th></th>
                <th>{round(getTableData(formData).totalInterest)}</th>
                <th>{round(getTableData(formData).totalCuotaAmortizacion)}</th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <th>Dinero total</th>
                <th></th>
                <th></th>
                <th>
                  {round(
                    getTableData(formData).totalInterest +
                      getTableData(formData).totalCuotaAmortizacion
                  )}
                </th>
                <th></th>
                <th></th>
              </tr>
            </tfoot>
          </table>
        </Box>
      )}
    </>
  );
};

export { Mortgage };
