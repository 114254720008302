import styled from "@emotion/styled";

const H1 = styled.h1`
  font-weight: bold;
  font-size: 3rem;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const H2 = styled.h2`
  font-size: 40px;
  text-align: center;
  padding-bottom: 16px;
`;

const H3 = styled.h3`
  font-size: 20px;
  text-align: center;
  padding-top: 48px;
  padding-bottom: 8px;
`;

const H4 = styled.h4`
  font-weight: bold;
  padding: 12px 0 6px;
`;

const H5 = styled.h5`
  margin-bottom: 20px;
  margin-left: 20px;
  font-weight: bold;
  text-transform: capitalize;
`;

const H6 = styled.h6`
  font-weight: bold;
  font-size: 14px;
  padding-bottom: 4px;
`;

export const TitleH1 = ({ children }: { children: string }) => {
  return <H1>{children}</H1>;
};

export const TitleH2 = ({ children }: { children: string }) => {
  return <H2>{children}</H2>;
};

export const TitleH3 = ({ children }: { children: string }) => {
  return <H3>{children}</H3>;
};

export const TitleH4 = ({ children }: { children: string }) => {
  return <H4>{children}</H4>;
};

export const TitleH5 = ({ children }: { children: string }) => {
  return <H5>{children}</H5>;
};

export const TitleH6 = ({ children }: { children: string }) => {
  return <H6>{children}</H6>;
};
