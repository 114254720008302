export const DropdownArrow = () => {
  return (
    <svg
      width="10px"
      height="6px"
      viewBox="0 0 10 6"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="01_Campaigns"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Dashboard_01"
          transform="translate(-1237.000000, -43.000000)"
          stroke="#495057"
        >
          <g id="Group-5" transform="translate(658.000000, 28.000000)">
            <path
              d="M579,16 C582.333105,18.6666667 583.999657,20 583.999657,20 C583.999657,20 585.666438,18.6666667 589,16"
              id="Path-3-Copy-3"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
