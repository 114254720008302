import { useState, useEffect } from "react";
import { TitleH5 } from "../titles/titlles";
import { Box } from "../container/Box";
import { SimulationForm } from "../form/SimulationForm";
import { GenericForm } from "../form/GenericForm";
import { Checkbox } from "../inputs/inputs";
import { LineChartCustom } from "../charts/LineChartCustom";

const Simulation = () => {
  const lines = [
    {
      name: "Accumulated Savings",
      dataKey: "accumulatedSavings",
      stroke: "#56b4ff",
    },
    {
      name: "Accumulated Investment",
      dataKey: "investmentData.accumulatedInvestment",
      stroke: "#000000",
    },
    {
      name: "Accumulated Contributions",
      dataKey: "investmentData.accumulatedContributions",
      stroke: "#009e73",
    },
    {
      name: "Accumulated Benefits",
      dataKey: "investmentData.accumulatedInvestedBenefits",
      stroke: "#e0bf00",
    },
    {
      name: "Potential Accumulated Benefits",
      dataKey: "investmentData.accumulatedPotentialBenefits",
      stroke: "#dd5037",
    },
    {
      name: "Accumulated Savings deducting inflation",
      dataKey: "accumulatedPresentSavings",
      stroke: "#0e02c4",
    },
  ];

  const lastSubmittedFormValuesFromLocalStorage =
    localStorage.getItem("linesToShow");

  const initialLinesToShow =
    lastSubmittedFormValuesFromLocalStorage !== null
      ? JSON.parse(lastSubmittedFormValuesFromLocalStorage)
      : lines;

  const [dataFromApi, setdataFromApi] = useState();
  const [linesToShow, setLinesToShow] = useState(initialLinesToShow);

  function handleOnchange(event: any, line: any) {
    const { value, checked } = event.target;

    if (checked) {
      setLinesToShow([...linesToShow, line]);
    } else {
      setLinesToShow(linesToShow?.filter((line: any) => line.name !== value));
    }
  }

  useEffect(() => {
    localStorage.setItem("linesToShow", JSON.stringify(linesToShow));
  }, [linesToShow]);

  return (
    <>
      <Box>
        <SimulationForm
          onSubmit={(values: any) => {
            if (!process.env.REACT_APP_API_URL) {
              throw new Error(
                "REACT_APP_API_URL must be present in the .env file"
              );
            }
            const baseUri = process.env.REACT_APP_API_URL;

            const returnedData = async () => {
              const response = await fetch(`${baseUri}/runSimulation`, {
                method: "POST",
                body: JSON.stringify(values),
                headers: {
                  "Content-Type": "application/json",
                },
              });
              const data = await response.json();
              setdataFromApi(data);
            };

            returnedData();
          }}
        />
      </Box>
      <Box>
        <GenericForm
          initialValues={{}}
          onSubmit={() => {}}
          withoutButton
          render={({ FormSection }: any) => {
            return (
              <FormSection>
                {lines.map((line) => {
                  const { name, stroke } = line;

                  return (
                    <Checkbox
                      key={name}
                      value={name}
                      label={name}
                      color={stroke}
                      checked={linesToShow.some(
                        (lineToShow: any) => lineToShow.name === name
                      )}
                      onChange={(event: any) => handleOnchange(event, line)}
                    />
                  );
                })}
              </FormSection>
            );
          }}
        />
      </Box>
      {dataFromApi &&
        Object.entries(dataFromApi["simulation"]).map(([key, values]) => (
          <Box chart key={key}>
            <TitleH5>{key}</TitleH5>
            <LineChartCustom
              dataScenario={values}
              scenario={key}
              linesToShow={linesToShow}
            />
          </Box>
        ))}
    </>
  );
};

export { Simulation };
