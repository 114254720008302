import { useEffect, useState } from "react";
import { Field } from "formik";
import { DropdownArrow } from "../icons/DropdownArrrow";
import numberInputOnWheelPreventChange from "../../../src/utils/numberInputOnWheelPreventChange";
import styled from "@emotion/styled";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const LabelValueGroup = styled.div``;

const Label = styled.label`
  display: block;
  padding-bottom: 4px;
  color: #495057;
`;

const InputGroup = styled.div`
  display: flex;
  align-items: center;
`;

const InputGroupIcon = styled.span`
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
  display: block;
`;

const input = `
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  outline-color: hsl(168, 100%, 30%);
`;

const inputCustom = `
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

const Input = styled(Field)`
  ${input}
`;

const InputGroupInput = styled(Field)`
  ${input}
  ${inputCustom}
`;

export const SimpleInput = ({
  id,
  inputName,
  label = "",
  placeholder,
  type = "number",
}: {
  id?: string;
  inputName: string;
  label: any;
  placeholder: string;
  type?: string;
}) => {
  return (
    <LabelValueGroup data-testid={id || inputName}>
      <Label htmlFor={id || inputName}>{label}</Label>
      <Input
        id={id || inputName}
        name={inputName}
        placeholder={placeholder}
        type={type}
        onWheel={numberInputOnWheelPreventChange}
      />
    </LabelValueGroup>
  );
};

export const CompoundInput = ({
  id,
  inputName,
  label,
  icon,
  placeholder,
  type = "number",
}: {
  id?: string;
  inputName: string;
  label: string;
  icon: string;
  placeholder: string;
  type?: string;
}) => {
  return (
    <LabelValueGroup data-testid={id || inputName}>
      <Label htmlFor={id || inputName}>{label}</Label>
      <InputGroup>
        <InputGroupIcon>{icon}</InputGroupIcon>
        <InputGroupInput
          id={id || inputName}
          name={inputName}
          placeholder={placeholder}
          type={type}
          onWheel={numberInputOnWheelPreventChange}
        />
      </InputGroup>
    </LabelValueGroup>
  );
};

const Container = styled.label`
  display: block;
  position: relative;
  padding-left: 24px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background-color: #009879;
    }

    &:checked ~ .checkmark:after {
      display: block;
    }
  }

  .checkmark:after {
    left: 5px;
    top: 2px;
    width: 3px;
    height: 7px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #eee;
  border-radius: 4px;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`;

export const Checkbox = ({
  label,
  name,
  value,
  color,
  checked,
  onChange,
  withAddSectionButton,
}: {
  label: string;
  name?: string;
  value: string;
  color?: string;
  checked?: boolean;
  onChange?: any;
  withAddSectionButton?: boolean;
}) => {
  return (
    <Container
      style={{
        color: color,
        width: withAddSectionButton ? "calc(100% - 120px)" : "100%",
      }}
    >
      <Field
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <Checkmark className="checkmark"></Checkmark>
      {label}
    </Container>
  );
};

const Toggle = styled.label`
  --width: 40px;
  --height: calc(var(--width) / 2);
  --border-radius: calc(var(--height) / 2);
  padding-right: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ToggleFill = styled.div`
  position: relative;
  width: var(--width);
  height: var(--height);
  border-radius: var(--border-radius);
  background: #dddddd;
  transition: background 0.2s;
  margin-right: 8px;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: var(--height);
    width: var(--height);
    background: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: var(--border-radius);
    transition: transform 0.2s;
  }
`;

const ToggleInput = styled(Field)`
  position: absolute;

  &:checked ~ .toggle__fill {
    background: #009879;
  }

  &:checked ~ .toggle__fill::after {
    transform: translateX(var(--height));
  }
`;

export const SlideCheckBox = ({
  values,
  inputName,
  optionTrue,
  optionFalse,
  onClick,
}: {
  values: any;
  inputName: string;
  optionTrue: string;
  optionFalse: string;
  onClick?: () => void;
}) => {
  return (
    <LabelValueGroup>
      <Toggle htmlFor={inputName}>
        <ToggleInput
          name={inputName}
          type="checkbox"
          id={inputName}
          onClick={onClick}
        />
        <ToggleFill className="toggle__fill"></ToggleFill>
        <span>{values[inputName] ? optionTrue : optionFalse}</span>
      </Toggle>
    </LabelValueGroup>
  );
};

const WrapperRange = styled.div`
  position: relative;
  padding-top: 32px;
`;

const RangeInput = styled(Field)`
  width: 30px;
  border: 1px solid hsl(210deg 11% 18%);
  border-radius: 4px;
  position: absolute;
  top: 8px;
  text-align: center;
  outline-color: hsl(168, 100%, 30%);
`;

export const Range = ({
  eventName,
  event,
  setFieldValue,
  simulationDuration,
}: {
  eventName: string;
  event: any;
  setFieldValue: any;
  simulationDuration: number;
}) => {
  const [lowerBound, setLowerBound] = useState(event.startDate);
  const [upperBound, setUpperBound] = useState(event.endDate);

  const onSliderChange = (value: any) => {
    setLowerBound(value[0]);
    setUpperBound(value[1]);
    setFieldValue(`events.${eventName}.startDate`, value[0]);
    setFieldValue(`events.${eventName}.endDate`, value[1]);
  };

  useEffect(() => {
    setLowerBound(event.startDate);
  }, [event.startDate]);

  useEffect(() => {
    setUpperBound(event.endDate);
  }, [event.endDate]);

  const min = 1;
  const max = simulationDuration;

  // TODO: Cuando uso estos componentes de styled components da problemas, porque es como que se crea un componente nuevo cada vez, tendrían que estar fuera del componente pero necesito los valores de los estados para mover el slider, de momento pongo el estilo en linea hasta que se me ocurra una mejor solucion
  // const RangeInputLeft = styled(RangeInput)`
  //   left: calc((100% / ${max - min}) * ${lowerBound - min} - 6%);
  // `;

  // const RangeInputRight = styled(RangeInput)`
  //   left: calc((100% / ${max - min}) * ${upperBound - min} - 6%);
  // `;

  return (
    <WrapperRange>
      <RangeInput
        style={{
          left: `calc((100% / ${max - min}) * ${
            Math.max(lowerBound, min) - min
          } - 6%)`,
        }}
        id={`${eventName}StartDate`}
        name={`events.${eventName}.startDate`}
        type="number"
        onWheel={numberInputOnWheelPreventChange}
        data-testid={`${eventName}StartDate`}
      />
      <RangeInput
        style={{
          left: `calc((100% / ${max - min}) * ${
            Math.min(upperBound, max) - min
          } - 6%)`,
        }}
        id={`${eventName}EndDate`}
        name={`events.${eventName}.endDate`}
        type="text"
        onWheel={numberInputOnWheelPreventChange}
        data-testid={`${eventName}EndDate`}
      />
      <Slider
        range
        min={min}
        max={max}
        allowCross={false}
        value={[lowerBound, upperBound]}
        onChange={onSliderChange}
      />
    </WrapperRange>
  );
};

const DropDownContainer = styled.div`
  margin: 0 auto;
  position: relative;
`;

const DropDownHeader = styled.div`
  ${input}
  display: flex;
  justify-content: space-between;
  outline: 1px solid #ced4da;
  border: 2px solid transparent;
  outline-offset: -1px;
  height: 38px;
`;

const DropDownListContainer = styled.div`
  position: absolute;
  width: 100%;
`;

const DropDownList = styled.ul`
  color: #495057;
  line-height: 1.5;
  border: 1px solid #ced4da;
  border-top: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: white;
`;

const ListItem = styled.li`
  list-style: none;
  padding: 4px 12px;

  &:hover {
    background-color: hsl(0deg 0% 96%);
  }
`;

const Icon = styled.span`
  display: flex;
  align-items: center;
`;

export const Select = ({
  id,
  label,
  options,
  setFieldValue,
}: {
  id: string;
  label: string;
  options: any;
  setFieldValue: any;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Spain");

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (value: string) => () => {
    setSelectedOption(value);
    setIsOpen(false);

    setFieldValue("general.residentCountry", value);
  };

  return (
    <LabelValueGroup>
      <Label htmlFor={id}>{label}</Label>
      <DropDownContainer>
        <DropDownHeader
          data-testid={id}
          onClick={toggling}
          style={{
            ...(isOpen && {
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              border: "2px solid hsl(168, 100%, 30%)",
              outline: "none",
            }),
          }}
        >
          {selectedOption}
          <Icon>
            <DropdownArrow />
          </Icon>
        </DropDownHeader>
        {isOpen && (
          <DropDownListContainer>
            <DropDownList>
              {options.map((option: string) => (
                <ListItem
                  onClick={onOptionClicked(option)}
                  key={option}
                  data-testid={option}
                >
                  {option}
                </ListItem>
              ))}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
    </LabelValueGroup>
  );
};
