import { useAuth0 } from "@auth0/auth0-react";
import styled from "@emotion/styled";
import React from "react";

const Profile = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (!user) {
    return <div>No user</div>;
  }

  if (isAuthenticated) {
    console.log("user", user);

    const UserProfile = styled.div`
      margin-left: 16px;
      display: flex;
      align-items: center;
    `;

    const Name = styled.p`
      margin-right: 8px;
      text-transform: capitalize;
    `;

    const Img = styled.img`
      border-radius: 50%;
      width: 24px;
    `;

    return (
      <UserProfile>
        <Name>{user.name}</Name>
        <Img src={user.picture} alt={user.name} />
        {/* <p>{user.email}</p> */}
      </UserProfile>
    );
  }

  throw new Error("Invalid component");
};

export default Profile;
