/** @jsxImportSource @emotion/react */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { jsx, css } from "@emotion/react";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import { Irpf } from "./components/irpf/Irpf";
import { EquivalentMoney } from "./components/equivalentMoney/equivalentMoney";
import { Simulation } from "./components/simulation/Simulation";
import { Mortgage } from "./components/mortgage/Mortgage";
import LoginButton from "./components/auth/login";
import LogoutButton from "./components/auth/logout";
import Profile from "./components/auth/profile";
import { TitleH1 } from "./components/titles/titlles";
import "./App.css";
import styled from "@emotion/styled";

const Home = () => {
  const Section = styled.section`
    max-width: 600px;
    margin: auto;
  `;

  return (
    <Section>
      <TitleH1>Bienvenido a las herramientas de calculo de finanzas</TitleH1>
      <p>
        Hay disponibles varias herramientas para ayudar a gestionar las finanzas
        personales
      </p>
    </Section>
  );
};

function App() {
  const App = styled.div`
    height: calc(100vh - 72px);
    display: -webkit-box;
    display: flex;
    flex-direction: column;
  `;

  const Header = styled.header`
    position: fixed;
    z-index: 20;
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    background-color: hsl(0deg 0% 11%);
    padding: 16px;
    color: white;
  `;

  const StyledLink = styled(Link)`
    color: white;
    text-decoration: none;
    padding: 8px;
    font-weight: bold;
  `;

  const HeaderItem = styled.div`
    padding: 8px;
  `;

  const Main = styled.main`
    position: relative;
    top: 72px;
    display: flex;
    flex: 1;
  `;

  const NavBar = styled.nav`
    position: fixed;
    height: calc(100vh - 72px);
    z-index: 10;
    display: flex;
    flex-direction: column;
    min-width: 200px;
    background-color: hsl(210deg 11% 18%);
  `;

  const NavItem = styled(Link)`
    color: white;
    font-size: 1rem;
    text-decoration: none;
    height: 60px;
    border-bottom: 2px solid hsl(0deg 0% 11%);
    display: flex;
    align-items: center;

    &:hover {
      background-color: hsl(204deg 8% 24%);
    }

    &:focus {
      background-color: hsl(204deg 8% 24%);
    }

    p {
      padding-left: 16px;
    }
  `;

  const Content = styled.div`
    width: calc(100% - 200px);
    position: relative;
    left: 200px;
    background-color: hsl(0deg 0% 96%);
    padding: 48px 80px;
  `;

  return (
    <Router>
      <App>
        <Header>
          <StyledLink to="/">Home</StyledLink>
          <HeaderItem>
            <LoginButton />
          </HeaderItem>
          <HeaderItem>
            <LogoutButton />
          </HeaderItem>
          <Profile />
        </Header>
        <Main>
          <NavBar>
            <NavItem to="/simulation">
              <div>
                <p>Simulación</p>
              </div>
            </NavItem>
            <NavItem to="/irpf">
              <div>
                <p>Calculo del irpf</p>
              </div>
            </NavItem>
            <NavItem to="/equivalentMoney">
              <div>
                <p>Dinero equivalente</p>
              </div>
            </NavItem>
            <NavItem to="/mortgage">
              <div>
                <p>Hipoteca</p>
              </div>
            </NavItem>
          </NavBar>
          <Content>
            <Switch>
              <Route path="/irpf">
                <Irpf />
              </Route>
              <Route path="/simulation">
                <Simulation />
              </Route>
              <Route path="/equivalentMoney">
                <EquivalentMoney />
              </Route>
              <Route path="/mortgage">
                <Mortgage />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </Content>
        </Main>
      </App>
    </Router>
  );
}

export default App;
